import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Link } from "@material-ui/core";
import Image from 'next/image'
function LandingBrandSlider(props) {
  const breakpoints = {
    320: {
      slidesPerView: 3,
    },
    480: {
      slidesPerView: 4,
    },
    640: {
      slidesPerView: 5,
    },
    992: {
      slidesPerView: 6,
    },
    1080: {
      slidesPerView: 7,
    },
    1200: {
      slidesPerView: 8,
    },
  };
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={8}
        navigation
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
        breakpoints={breakpoints}
      >
        <SwiperSlide className="text-center">
          <Link href="/sell-used-mobile-phones/apple-iphone">
            <a className="">
              <Image
                  src="/images/brand-apple.webp"
                  width={128}
                  height={128}
                  alt="brand-apple"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-mobile-phones/samsung">
            <a className="">
              <Image
                  src="/images/brand-samsung.webp"
                  width={128}
                  height={128}
                  alt="brand-samsung"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-mobile-phones/google">
            <a className="">
              <Image
                  src="/images/brand-google.webp"
                  width={128}
                  height={128}
                  alt="brand-google"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-mobile-phones/xiaomi">
            <a className="">
              <Image
                  src="/images/brand-xiaomiRedmi.webp"
                  width={128}
                  height={128}
                  alt="brand-xiaomiRedmi"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-mobile-phones/realme">
            <a className="">
              <Image
                  src="/images/brand-realme.webp"
                  width={128}
                  height={128}
                  alt="brand-realme"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-mobile-phones/oneplus">
            <a className="">
              <Image
                  src="/images/brand-onePlus.webp"
                  width={128}
                  height={128}
                  alt="brand-onePlus"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-mobile-phones/motorola">
            <a className="">
              <Image
                  src="/images/brand-motorola.webp"
                  width={128}
                  height={128}
                  alt="brand-motorola"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-laptops/other-laptop">
            <a className="">
              <Image
                  src="/images/brand-hp.webp"
                  width={128}
                  height={128}
                  alt="brand-hp"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-mobile-phones/nothing-phone">
            <a className="">
              <Image
                  src="/images/brand-nothing.webp"
                  width={128}
                  height={128}
                  alt="brand-nothing"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-mobile-phones/oppo">
            <a className="">
              <Image
                  src="/images/brand-oppo.webp"
                  width={128}
                  height={128}
                  alt="brand-oppo"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-mobile-phones/vivo">
            <a className="">
              <Image
                  src="/images/brand-vivo.webp"
                  width={128}
                  height={128}
                  alt="brand-vivo"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-mobile-phones/nokia">
            <a className="">
              <Image
                  src="/images/brand-nokia.webp"
                  width={128}
                  height={128}
                  alt="brand-nokia"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-laptops/other-laptop">
            <a className="">
              <Image
                  src="/images/brand-lenovo.webp"
                  width={128}
                  height={128}
                  alt="brand-lenovo"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-mobile-phones/asus">
            <a className="">
              <Image
                  src="/images/brand-asus.webp"
                  width={128}
                  height={128}
                  alt="brand-asus"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-laptops/other-laptop">
            <a className="">
              <Image
                  src="/images/brand-dell.webp"
                  width={128}
                  height={128}
                  alt="brand-dell"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-laptops/other-laptop">
            <a className="">
              <Image
                  src="/images/brand-microsoft.webp"
                  width={128}
                  height={128}
                  alt="brand-microsoft"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-laptops/other-laptop">
            <a className="">
              <Image
                  src="/images/brand-intel.webp"
                  width={128}
                  height={128}
                  alt="brand-intel"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide className="text-center">
          <Link href="/sell-used-othergadgets">
            <a className="">
              <Image
                  src="/images/brand-amazon.webp"
                  width={128}
                  height={128}
                  alt="brand-amazon"
                  className="mx-auto rounded-circle w-100 h-auto"
                />
            </a>
          </Link>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default LandingBrandSlider;
