import Accordion from "react-bootstrap/Accordion";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import { useState } from "react";
import { Link } from "@material-ui/core";


function LandingFAQ(props) {

  const [activeTab, setActiveTab] = useState('seller');
  const [activeKey, setActiveKey] = useState('0');
  const handleSelectTab = (key) => {
    setActiveTab(key);
    // Set the default active key for the corresponding tab
    if (key === 'seller') {
      setActiveKey('0');
    } else if (key === 'buyer') {
      setActiveKey('00');
    }
  };

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const PlusIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5V7.5h6a.5.5 0 0 1 0 1h-6v6a.5.5 0 0 1-1 0v-6H1.5a.5.5 0 0 1 0-1h6V1.5A.5.5 0 0 1 8 1z"/>
    </svg>
  );
  
  const MinusIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M1.5 8a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 0 1h-12a.5.5 0 0 1-.5-.5z"/>
    </svg>
  );

  return (
    <>
      <div className="accordiontabsec position-relative">
        <Tabs defaultActiveKey="general" id="uncontrolled-tab-example" className="mb-3 border-0" activeKey={activeTab} onSelect={handleSelectTab}>
          <Tab eventKey="seller" title="Seller">
            <div>
              <Accordion activeKey={activeKey}>
                <div className="border-top">
                  <Card.Header
                    className={activeKey === "0" ? "active" : ""}
                    onClick={() => handleToggle("0")}
                  >
                    <Accordion.Toggle variant="link" eventKey="0">
                    Do you serve my pincode?
                      {activeKey === '0' ? <MinusIcon /> : <PlusIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <div className="pb-3">
                    Yes we cover over 25000 pin codes across India
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="border-top">
                  <Card.Header
                    className={activeKey === "1" ? "active" : ""}
                    onClick={() => handleToggle("1")}
                  >
                    <Accordion.Toggle variant="link" eventKey="1">
                    When will I get my payment?
                      {activeKey === '1' ? <MinusIcon /> : <PlusIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <div className="pb-3">
                    Payment will be made promptly after we receive and verify the device at our end.


                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="border-top">
                  <Card.Header
                    className={activeKey === "2" ? "active" : ""}
                    onClick={() => handleToggle("2")}
                  >
                    <Accordion.Toggle variant="link" eventKey="2">
                    How do I trust that I will get paid?
                      {activeKey === '2' ? <MinusIcon /> : <PlusIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <div className="pb-3">
                      <p>Trust is key when selling used gadgets, and Budli.in has earned recognition from reputed media like BBC, Fortune Magazine, CNBC, and Inc 42 for our quality and reliability.</p>
                      <p>See for yourself: explore our positive Google Reviews, user testimonials on our site, and feedback videos on YouTube.</p>
                      <div>With awards and a proven track record, Budli.in is your trusted source for selling used mobiles, tablets, and laptops across India!
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="border-top">
                  <Card.Header
                    className={activeKey === "3" ? "active" : ""}
                    onClick={() => handleToggle("3")}
                  >
                    <Accordion.Toggle variant="link" eventKey="3">
                    Will I get the same amount as promised on the website?
                      {activeKey === '3' ? <MinusIcon /> : <PlusIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <div className="pb-3">
                    Yes, we will pay the amount listed on our website unless there is a discrepancy with the device. If there is a mismatch, we will provide a revised quote. If you do not accept the new quote, we will return the device to you. Please note that return logistics and quality check charges will apply.
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="border-top">
                  <Card.Header
                    className={activeKey === "4" ? "active" : ""}
                    onClick={() => handleToggle("4")}
                  >
                    <Accordion.Toggle variant="link" eventKey="4">
                    How do you ensure data privacy?
                      {activeKey === '4' ? <MinusIcon /> : <PlusIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <div className="pb-3">
                    We recommend sellers to remove all passwords / account lock and do a complete factory reset before the device is picked up. We will check at our end to make sure there is no user data on the device. For more information, please visit: <Link href="https://budli.in/privacy-policy">https://budli.in/privacy-policy</Link> 
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="border-top">
                  <Card.Header
                    className={activeKey === "5" ? "active" : ""}
                    onClick={() => handleToggle("5")}
                  >
                    <Accordion.Toggle variant="link" eventKey="5">
                    My device is not listed. Can I sell it?
                      {activeKey === '5' ? <MinusIcon /> : <PlusIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="5">
                    <div className="pb-3">
                    Yes. Please submit details of your device at: <Link href="https://budli.in/request-a-quote">https://budli.in/request-a-quote</Link>
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="border-top">
                  <Card.Header
                    className={activeKey === "6" ? "active" : ""}
                    onClick={() => handleToggle("6")}
                  >
                    <Accordion.Toggle variant="link" eventKey="6">
                    Do you buy in bulk?
                      {activeKey === '6' ? <MinusIcon /> : <PlusIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="6">
                    <div className="pb-3">
                    Yes we buy directly from companies in bulk as well. Please submit your enquiry here: <Link href="https://budli.in/budli-for-business">https://budli.in/budli-for-business</Link>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </div>
          </Tab>
          <Tab eventKey="buyer" title="Buyer">
            <div>
              <Accordion activeKey={activeKey}>
                <div className="border-top">
                  <Card.Header
                    className={activeKey === "00" ? "active" : ""}
                    onClick={() => handleToggle("00")}
                  >
                    <Accordion.Toggle variant="link" eventKey="00">
                    When will I get delivery of my device after placing order?
                      {activeKey === '00' ? <MinusIcon /> : <PlusIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="00">
                    <div className="pb-3">
                    We usually ship the products within 1 working day of your order and delivery usuall takes within 3-4 working days.
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="border-top">
                  <Card.Header
                    className={activeKey === "11" ? "active" : ""}
                    onClick={() => handleToggle("11")}
                  >
                    <Accordion.Toggle variant="link" eventKey="11">
                    What is the warranty on the product?
                      {activeKey === '11' ? <MinusIcon /> : <PlusIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="11">
                    <div className="pb-3">
                    Refurbished mobiles, tablets, laptops come with 6 months Budli Service Warranty. For more information, please check: <Link href="https://buy.budli.in/pages/warranty">https://buy.budli.in/pages/warranty</Link>
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="border-top">
                  <Card.Header
                    className={activeKey === "22" ? "active" : ""}
                    onClick={() => handleToggle("22")}
                  >
                    <Accordion.Toggle variant="link" eventKey="22">
                    What is the Return Policy?
                      {activeKey === '22' ? <MinusIcon /> : <PlusIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="22">
                    <div className="pb-3">
                    In case the product is not as described, you can lodge a complaint and return it within 7 days. For more information, please check: <Link href="https://buy.budli.in/pages/shipping-and-returns">https://buy.budli.in/pages/shipping-and-returns</Link>
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="border-top">
                  <Card.Header
                    className={activeKey === "33" ? "active" : ""}
                    onClick={() => handleToggle("33")}
                  >
                    <Accordion.Toggle variant="link" eventKey="33">
                    Can I buy on COD (Cash on Delivery)?
                      {activeKey === '33' ? <MinusIcon /> : <PlusIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="33">
                    <div className="pb-3">
                    Yes. In most cases you can order on COD by paying an initial deposit amount to us at <Link href="https://budli.in/pay">https://budli.in/pay</Link> to confirm the order. This advance is required due to the high number of COD returns from buyers who are not serious.
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="border-top">
                  <Card.Header
                    className={activeKey === "44" ? "active" : ""}
                    onClick={() => handleToggle("44")}
                  >
                    <Accordion.Toggle variant="link" eventKey="44">
                    Will I get an invoice?
                      {activeKey === '44' ? <MinusIcon /> : <PlusIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="44">
                    <div className="pb-3">
                    Yes. We provide a GST Invoice for every order. Please inform us and it will be sent to your email address.
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="border-top">
                  <Card.Header
                    className={activeKey === "55" ? "active" : ""}
                    onClick={() => handleToggle("55")}
                  >
                    <Accordion.Toggle variant="link" eventKey="55">
                    How do I trust this website to buy used or refurbished mobiles, laptops or tablets?
                      {activeKey === '55' ? <MinusIcon /> : <PlusIcon />}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="55">
                    <div className="pb-3">
                      <p>We understand that trust is key when buying refurbished or pre-owned gadgets. Every device goes through our stringent 25 point quality check process before it is shipped.</p>
                      <p>That's why we're proud to be recognized by some of the most reputable media outlets and organizations around. Budli.in has been featured in BBC, Fortune Magazine, CNBC, and Inc 42, highlighting our commitment to quality and reliability.</p>
                      <p>But don't just take our word for it! Dive into the wealth of positive feedback from our users across various platforms. Check out our glowing Google Reviews, read through user testimonials on our site, and watch feedback videos on YouTube to see why countless customers trust Budli.in for their gadget needs.</p>
                      <p>With awards from esteemed sources and a track record of satisfied customers, Budli.in stands as your trusted source for high-quality refurbished and pre-owned gadgets. Explore our wide selection of second-hand mobiles, laptops, tablets, and more with confidence!</p>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default LandingFAQ;
