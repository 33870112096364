
import Image from 'next/image'
function WhyBudli(props) {
  return (
    <>
        <div className="row mob-row5">
            <div className="col-md-4 mb-4 col-6">
                <div className="rounded-lg shadow-sm bg-white h-100 p-lg-5 p-md-3 p-3 text-center d-flex flex-column gap-2">
                    <Image
                    src="/images/whybudli1.svg"
                    width={100}
                    height={100}
                    alt=""
                    className="d-block mx-auto rounded-circle"
                    />
                    <h5 className="fw-600 text-default-red m-0">Best Value</h5>
                    <div className="text-dark">Get top value whether you're buying or selling</div>
                </div>
            </div>
            <div className="col-md-4 mb-4 col-6">
                <div className="rounded-lg shadow-sm bg-white h-100 p-lg-5 p-md-3 p-3 text-center d-flex flex-column gap-2">
                    <Image
                    src="/images/whybudli2.svg"
                    width={100}
                    height={100}
                    alt=""
                    className="d-block mx-auto rounded-circle"
                    />
                    <h5 className="fw-600 text-default-red m-0">Safe and Secure</h5>
                    <div className="text-dark">Trustworthy process with data security measures</div>
                </div>
            </div>
            <div className="col-md-4 mb-4 col-6">
                <div className="rounded-lg shadow-sm bg-white h-100 p-lg-5 p-md-3 p-3 text-center d-flex flex-column gap-2">
                    <Image
                    src="/images/whybudli3.svg"
                    width={100}
                    height={100}
                    alt=""
                    className="d-block mx-auto rounded-circle"
                    />
                    <h5 className="fw-600 text-default-red m-0">Eco-Friendly</h5>
                    <div className="text-dark">Make an environmentally friendly choice</div>
                </div>
            </div>
            <div className="col-md-4 mb-4 col-6">
                <div className="rounded-lg shadow-sm bg-white h-100 p-lg-5 p-md-3 p-3 text-center d-flex flex-column gap-2">
                    <Image
                    src="/images/whybudli4.svg"
                    width={100}
                    height={100}
                    alt=""
                    className="d-block mx-auto rounded-circle"
                    />
                    <h5 className="fw-600 text-default-red m-0">Hassle-Free</h5>
                    <div className="text-dark">Enjoy free pickup and shipping across India</div>
                </div>
            </div>
            <div className="col-md-4 mb-4 col-6">
                <div className="rounded-lg shadow-sm bg-white h-100 p-lg-5 p-md-3 p-3 text-center d-flex flex-column gap-2">
                    <Image
                    src="/images/whybudli5.svg"
                    width={100}
                    height={100}
                    alt=""
                    className="d-block mx-auto rounded-circle"
                    />
                    <h5 className="fw-600 text-default-red m-0">Simplicity</h5>
                    <div className="text-dark">Streamlined, straightforward process</div>
                </div>
            </div>
            <div className="col-md-4 mb-4 col-6">
                <div className="rounded-lg shadow-sm bg-white h-100 p-lg-5 p-md-3 p-3 text-center d-flex flex-column gap-2">
                    <Image
                    src="/images/whybudli6.svg"
                    width={100}
                    height={100}
                    alt=""
                    className="d-block mx-auto rounded-circle"
                    />
                    <h5 className="fw-600 text-default-red m-0">Social Impact</h5>
                    <div className="text-dark">Join us in bridging India's digital divide</div>
                </div>
            </div>
        </div>
    </>
  );
}

export default WhyBudli;
