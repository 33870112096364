import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useState } from "react";
import { Link } from "@material-ui/core";


function SEOfaq(props) {
  const [activeKey, setActiveKey] = useState("0");

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const PlusIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5V7.5h6a.5.5 0 0 1 0 1h-6v6a.5.5 0 0 1-1 0v-6H1.5a.5.5 0 0 1 0-1h6V1.5A.5.5 0 0 1 8 1z"/>
    </svg>
  );
  
  const MinusIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M1.5 8a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 0 1h-12a.5.5 0 0 1-.5-.5z"/>
    </svg>
  );

  return (
    <>
      <div className="accordiontabsec position-relative">
            <Accordion defaultActiveKey="0">
                <div className="border-0">
                    <Card.Header className={activeKey === "0" ? "active" : ""} onClick={() => handleToggle("0")}>
                        <Accordion.Toggle variant="link" eventKey="0">
                        Sell Used Mobiles, Tablets, and Laptops Hassle-Free at Budli
                            {activeKey === '0' ? <MinusIcon /> : <PlusIcon />}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                    <div className="pb-3">
                    <p>Welcome to <Link href="https://budli.in/">Budli.in</Link>, your go-to platform for selling used mobiles, tablets, and laptops effortlessly. We make it simple to get the best value for your old devices with just a few easy steps. Ready to clear out your clutter and earn some cash? Here’s how <Link href="https://budli.in/">Budli.in</Link> works.</p>
                    <div><strong>Why Choose <Link href="https://budli.in/">Budli.in</Link>?</strong></div>
                    <ul>
                      <li><strong>Declutter and Earn:</strong> Turn your unused gadgets into extra cash while freeing up space.</li>
                      <li><strong>Eco-Friendly:</strong> Contribute to reducing e-waste by recycling your electronics responsibly.</li>
                      <li><strong>Simple Process:</strong> Our user-friendly platform offers a seamless way to sell your devices.</li>
                      <li><strong>Fair Prices:</strong> Get competitive quotes based on current market values.</li>
                      <li><strong>Fast Payments:</strong> Receive your money quickly after we evaluate your device.</li>
                      <li><strong>Secure Transactions:</strong> Your data is safe with us, from start to finish.</li>
                    </ul>
                    <div><strong>Devices We Buy</strong></div>
                    <ul>
                        <li><strong>Mobiles: </strong> From iPhones to Samsung, One Plus to Xiaomi, you sell almost any brand of smartphones to us</li>
                        <li><strong>Tablets: </strong> We accept iPads and other popular tablets.</li>
                        <li><strong>Laptops: </strong> Sell your MacBooks, Dell, Lenovo, and more.</li>
                        <li><strong>Other Gadgets: </strong> Sell used Smart Speakers including Homepod, Alexa enabled Echo and Echo Dot, Google Home, Media streaming devices like Fire TV, Apple TV or popular speakers, headphones and TWS earpods. You can even sell smartwatches like Apple Watch or other popular models.</li>
                    </ul>
                    <p>Selling your used gadgets has never been easier. Join thousands of satisfied customers who’ve decluttered and earned with Budli.in. Ready to get started? Sell Now!</p>
                    </div>
                    </Accordion.Collapse>
                </div>
                <div className="border-0">
                    <Card.Header className={activeKey === "1" ? "active" : ""} onClick={() => handleToggle("1")} >
                        <Accordion.Toggle variant="link" eventKey="1">
                        Environmental Benefits of Selling Used Gadgets at Budli
                            {activeKey === '1' ? <MinusIcon /> : <PlusIcon />}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <div className="pb-3">
                            <p>At <Link href="https://budli.in/">Budli.in</Link>, we’re not just about getting you the best value for your used mobiles, tablets, and laptops, but also about making a positive impact on the environment. Here’s how selling your used gadgets with <Link href="https://budli.in/">Budli.in</Link> benefits the planet:</p>
                            <div><strong>Reduce E-Waste</strong></div>
                            <p>E-waste is a massive global problem, with millions of tons of electronic waste ending up in landfills every year. By selling your used gadgets on <Link href="https://budli.in/">Budli.in</Link>, you contribute to reducing this harmful waste. Instead of letting your old devices pile up as trash, they get a second life, either through recycling or refurbishment.</p>
                            <div><strong>Save Natural Resources</strong></div>
                            <p>Manufacturing new electronics consumes a significant amount of natural resources, including precious metals and minerals. By opting to sell your used gadgets, you help conserve these resources. Refurbished devices reduce the need for new raw materials, leading to lesser environmental impact.</p>
                            <div><strong>Lower Carbon Footprint</strong></div>
                            <p>Producing new devices also means a lot of energy consumption and carbon emissions. Selling your used mobiles, tablets, and laptops to <Link href="https://budli.in/">Budli.in</Link> reduces the demand for new products, thereby lowering the overall carbon footprint. This small step can make a big difference in combating climate change.</p>
                            <div><strong>Promote a Circular Economy</strong></div>
                            <p>When you sell your gadgets to <Link href="https://budli.in/">Budli.in</Link>, you are supporting a circular economy where products are reused and recycled. This sustainable approach minimizes waste and maximizes the use of resources, creating a healthier environment for everyone.</p>
                        </div>
                    </Accordion.Collapse>
                </div>
            </Accordion>
      </div>
    </>
  );
}

export default SEOfaq;
