import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import Link from "next/link";
import Image from 'next/image'

function Videos(props) {
  const breakpoints = {
    320: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 2,
    },
    640: {
      slidesPerView: 3,
    },
  };
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={3}
        navigation
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
        breakpoints={breakpoints}
      >
        <SwiperSlide>
          <div className="vdo-container">
            <Link href="https://www.youtube.com/watch?v=qi1vavAnpyk">
              <a target="_blank" className="d-block">
                <Image
                  src="/images/vdo-1.webp"
                  width={595}
                  height={334}
                  alt="qi1vavAnpyk"
                  className="w-100 h-auto"
                />
              </a>
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="vdo-container">
            <Link href="https://www.youtube.com/watch?v=9_1beMqTb6k">
              <a target="_blank" className="d-block">
                <Image
                  src="/images/vdo-2.webp"
                  width={595}
                  height={334}
                  alt="9_1beMqTb6k"
                  className="w-100 h-auto"
                />
              </a>
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="vdo-container">
            <Link href="https://www.youtube.com/watch?v=Gi64AWE0sL8">
              <a target="_blank" className="d-block">
                <Image
                  src="/images/vdo-3.webp"
                  width={595}
                  height={334}
                  alt="Gi64AWE0sL8"
                  className="w-100 h-auto"
                />
              </a>
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="vdo-container">
            <Link href="https://www.youtube.com/watch?v=beswmpWPkmQ">
              <a target="_blank" className="d-block">
                <Image
                  src="/images/vdo-4.webp"
                  width={595}
                  height={334}
                  alt="beswmpWPkmQ"
                  className="w-100 h-auto"
                />
              </a>
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="vdo-container">
            <Link href="https://www.youtube.com/watch?v=eFWGxfpgqMw">
              <a target="_blank" className="d-block">
                <Image
                  src="/images/vdo-5.webp"
                  width={595}
                  height={334}
                  alt="eFWGxfpgqMw"
                  className="w-100 h-auto"
                />
              </a>
            </Link>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default Videos;
